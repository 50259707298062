import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store, persistor } from "./redux/storeConfig/store"
import { PersistGate } from 'redux-persist/integration/react';
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import "./@fake-db"

import { UserProvider } from './context/context'
import { QueryClient, QueryClientProvider } from 'react-query'

const LazyApp = lazy(() => import("./App"))

const queryClient = new QueryClient()

// configureDatabase()
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Spinner />}>
            <Layout>
              <LazyApp />
            </Layout>
          </Suspense>
        </PersistGate>
      </Provider>
    </UserProvider>
  </QueryClientProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
