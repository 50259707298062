import React, { useContext } from "react"
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, } from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import { UserContext } from './../../../context/context'
import { URL_IMG } from "../../../config"
import imgDefault from './../../../assets/img/logo/avatarDefault.png?'
import { PURGE } from 'redux-persist';
import { useDispatch } from 'react-redux';

const UserDropdown = props => {
  const { activateAuth } = useContext(UserContext)
  const dispatch = useDispatch();
  return (
    <DropdownMenu >
      <DropdownItem onClick={() => {
        activateAuth(null)
        sessionStorage.clear();
        dispatch({
          type: PURGE,
          key: 'root', // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
          result: () => null // Func expected on the submitted action.
        });
      }}>
        <Icon.Power size={14} color={'#626262'} className="mr-50" />
        <span style={{ color: '#626262' }} className="align-middle">Cerrar sesión</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: []
  }
  user = (JSON.parse(sessionStorage.getItem('user')))

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }


  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {`${this.user.name}`}
              </span>
              <span className="user-status">
                {
                  this.user.profile === 2 ? 'Administrador' :
                    this.user.profile === 65 ? 'Compras' :
                      this.user.profile === 75 ? 'Comunicaciones' :
                        'Supply'
                }
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.user.photo ? `${URL_IMG}/${this.user.photo}` : imgDefault}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
